import { FC, ReactElement } from 'react'
import { useRouter } from 'next/router'
import { defaultAccountImage } from '@src/utils/constants'
import { Avatar } from '@components/ui/Avatar'

interface AccountSearchResultProps {
  account?: {
    id?: string | null
    displayName?: string | null
    profilePicture?: string | null
    title?: string | null
    slug?: string | null
  }
  accessoryRight?: ReactElement
  disabled?: boolean
  onAvatarPressed?: () => void
  onPress?: () => Promise<void>
  bottomText?: ReactElement
}

export const AccountSearchResult: FC<AccountSearchResultProps> = ({
  account,
  accessoryRight,
  onAvatarPressed,
  onPress,
  bottomText,
}) => {
  const router = useRouter()

  if (!account) {
    return null
  }

  const goToAccount = async () => {
    await router.push(`/${account.slug}`)
    onAvatarPressed?.()
  }

  return (
    <div className="flex items-center justify-between w-full py-1 px-4">
      <div
        className="flex items-center cursor-pointer"
        onClick={onPress ?? goToAccount}>
        <Avatar
          uri={account?.profilePicture || defaultAccountImage}
          size={43}
        />

        <div className="ml-2">
          <p className="text-base font-semibold text-ellipsis">
            {account.displayName}
          </p>
          <p className="text-sm text-gray-500 -mt-1 text-ellipsis">
            {account.title}
          </p>
          {bottomText && (
            <p className="text-xs text-gray-500 -mt-1">{bottomText}</p>
          )}
        </div>
      </div>

      {accessoryRight && <div className="ml-auto">{accessoryRight}</div>}
    </div>
  )
}
