interface AssetDimensions {
  width?: number
  height?: number
  deviceWidth?: number
  isStory?: boolean
}

interface DimensionsType {
  width: number
  height: number
}

export function adjustDimensions({
  width = 1920,
  height = 1080,
  deviceWidth = 1920,
  isStory = false,
}: AssetDimensions): DimensionsType {
  let adjustedHeight: number

  if (width === height) {
    // Square aspect ratio 1:1
    adjustedHeight = deviceWidth // Height equals device width for square
  } else if (width > height) {
    // Horizontal aspect ratio 16:9
    adjustedHeight = (deviceWidth * height) / width
  } else {
    // Vertical aspect ratio 4:5
    adjustedHeight = (deviceWidth * 5) / 4 // Ensures a 4:5 ratio

    if (isStory) {
      adjustedHeight = (deviceWidth * 16) / 9
    }
  }

  return { width: deviceWidth, height: Math.round(adjustedHeight) }
}
