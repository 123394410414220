import { HomePostType } from '@goatlab/marketplace-states/src/home/homeFeed.store'
import {
  Button,
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  cn,
} from '@goatlab/react-ui'
import React, { FC, memo } from 'react'
import { AssetRenderer } from './AssetRenderer'
import { MarketplaceAsset } from '@goatlab/marketplace-states/src/post/newPost.store'
import { useAssetViewer } from './useAssetViewer'

interface AssetViewerProps {
  assets: HomePostType['assets']
  isViewable?: boolean
  postId?: string
  isPreview?: boolean
}

export const AssetViewer: FC<AssetViewerProps> = memo(
  ({ assets, isViewable, postId, isPreview }) => {
    const [api, setApi] = React.useState<CarouselApi>()
    const { currentAssetIndex, snapToItem, isAssetViewable } = useAssetViewer({
      isViewable,
      postId,
    })

    React.useEffect(() => {
      if (!api) {
        return
      }

      snapToItem(api.selectedScrollSnap())

      api.on('select', () => {
        snapToItem(api.selectedScrollSnap())
      })
    }, [api])

    return (
      <div className="min-h-64">
        <Carousel setApi={setApi}>
          <CarouselContent>
            {assets.map((asset, index) => (
              <CarouselItem key={index}>
                <AssetRenderer
                  asset={asset as MarketplaceAsset}
                  index={index}
                  currentAssetIndex={currentAssetIndex}
                  isAssetViewable={isAssetViewable || false}
                  isPreview={isPreview}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>

        {(api?.scrollSnapList().length || 0) > 1 && (
          <div className={'py-1 text-center'}>
            {api?.scrollSnapList().map((_, index) => {
              const isCurrentIndex = index === currentAssetIndex - 1
              return (
                <Button
                  key={index}
                  size={'icon'}
                  className={cn(
                    'h-2 w-2 pl-1 ml-1 rounded-full border-1 hover:bg-blue-500',
                    isCurrentIndex
                      ? 'bg-blue-500 border-blue-500'
                      : 'bg-white border-blue-500',
                  )}
                  onClick={() => {
                    api.scrollTo(index)
                  }}
                />
              )
            })}
          </div>
        )}
      </div>
    )
  },
)
