import { format } from 'date-fns'

interface Duration { hours: number; minutes: number; seconds: number }

const calculateDuration = (seconds: number): Duration => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = Math.floor(seconds % 60)

  return { hours, minutes, seconds: remainingSeconds }
}

export const formatDuration = (seconds: number): string => {
  const duration = calculateDuration(seconds)

  const { hours, minutes, seconds: remainingSeconds } = duration
  const date = new Date(0, 0, 0, hours, minutes, remainingSeconds)

  return hours > 0 ? format(date, 'HH:mm:ss') : format(date, 'mm:ss')
}

export const formatTime = (milliseconds: number): string => {
  const seconds = Math.floor(milliseconds / 1000)
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60

  const date = new Date(0, 0, 0, hours, minutes, remainingSeconds)

  return hours > 0 ? format(date, 'HH:mm:ss') : format(date, 'mm:ss')
}
