import React from 'react'
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
} from '@goatlab/react-ui'
import { DataCenterList } from './DataCenterList'

// TODO: Dejar cambiado el componente base de Dialog para que funcione en mobile de manera visual correcta

interface DataCenterProps {
  isOpen: boolean
  onClose: () => void
}

export const DataCenterDialog: React.FC<DataCenterProps> = ({
  isOpen,
  onClose,
}) => {
  return (
    <Drawer open={isOpen} onOpenChange={onClose}>
      <DrawerContent>
        <DrawerHeader>
          <DrawerTitle>Data Center</DrawerTitle>
          <DrawerDescription></DrawerDescription>
        </DrawerHeader>

        <div className="flex items-center justify-center space-x-2 pb-8">
          <DataCenterList />
        </div>

        <div>
          <div className="bg-[#005d62] text-[#CDFD92] text-center font-bold text-xl h-12 leading-[3rem] mt-6 mb-10">
            Coming soon...
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  )
}
