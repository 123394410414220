import { FC, useRef, useEffect, useState } from 'react'
import { Player, videojs } from '@goatlab/react-ui'
import { MarketplaceAsset } from '@goatlab/marketplace-states/src/post/newPost.store'
import { cn } from '@src/utils/cn'
import useDelay from '@src/hooks/useDelay'

interface VideoPlayerProps {
  asset: MarketplaceAsset
  index: number
  currentAssetIndex: number
  isAssetViewable?: boolean
  isStory?: boolean
}

export const VideoPlayer: FC<VideoPlayerProps> = ({
  asset,
  isStory,
  index,
  currentAssetIndex,
  isAssetViewable,
}) => {
  const videoRef = useRef<HTMLDivElement | null>(null)
  const playerRef = useRef<Player | null>(null)
  const [, setIsFullScreen] = useState(false)
  const shouldPlay = currentAssetIndex === index && isAssetViewable
  const debounceShouldPlay = useDelay(shouldPlay, 1000)

  useEffect(() => {
    if (videoRef.current && !playerRef.current) {
      const videoElement = document.createElement('video-js')
      videoElement.classList.add('vjs-big-play-centered')
      videoRef.current.append(videoElement)

      playerRef.current = videojs(videoElement, {
        controls: true,
        autoplay: true,
        muted: true,
        fluid: true,
        preload: 'metadata', // Cargar solo metadatos inicialmente
        loop: true,
        poster: asset.thumbnailUrl, // Usar thumbnail hasta iniciar la reproducción
        bigPlayButton: false,
        sources: [{ src: asset.url, type: 'video/mp4' }],
      })

      playerRef.current.on('fullscreenchange', () => {
        setIsFullScreen(playerRef.current?.isFullscreen() || false)
      })
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose()
        playerRef.current = null
      }
    }
  }, [asset])

  useEffect(() => {
    if (playerRef.current && debounceShouldPlay) {
      playerRef.current.play?.().catch(err => {
        console.error('Autoplay was prevented:', err)
      })
    }

    if (playerRef.current && !debounceShouldPlay) {
      playerRef.current.pause()
      playerRef.current.currentTime(0) // Restablecer al inicio al pausar
    }
  }, [debounceShouldPlay])

  return (
    <div
      className={cn(
        'flex w-full h-full items-center justify-center overflow-hidden cursor-pointer',
        isStory ? 'aspect-w-9 aspect-h-16' : 'aspect-w-2 aspect-h-3',
      )}>
      <div ref={videoRef} className="w-full h-full" />
    </div>
  )
}
