import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { adjustDimensions } from '@src/utils/adjustDimensions'
import { useWindowSize } from 'react-use'
import type { PDFDocumentProxy } from 'pdfjs-dist'
import { MarketplaceAsset } from '@goatlab/marketplace-states/src/post/newPost.store'
import { useIs } from '@src/hooks/useIs'
import { FileText } from 'lucide-react'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
import { Button } from '@goatlab/react-ui'
import { PdfModal } from './PdfModal'
import Image from 'next/image'
import { defaultBackgroundImg } from '@src/utils/constants'

interface PdfPreviewProps {
  asset: MarketplaceAsset
  isPreview?: boolean
}

function encodeURL(url: string) {
  const urlParts = url.split('/')
  const encodedParts = urlParts.map((part, index) => {
    if (index < 3) {
      return part
    } else {
      return encodeURIComponent(part)
    }
  })
  return encodedParts.join('/')
}

export const PdfPreview: React.FC<PdfPreviewProps> = ({ asset, isPreview }) => {
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [numPages, setNumPages] = useState<number>(0)
  const { isMobile } = useIs()

  const handleDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy) => {
    setNumPages(numPages)
  }

  const dimensions = adjustDimensions({
    width: asset.width || 0,
    height: asset.height || 0,
    deviceWidth: useWindowSize().width,
  })

  const handlePreviewClick = () => {
    setIsFullScreen(true)
  }

  return (
    <div className="flex relative h-full justify-center items-center">
      {isPreview ? (
        <Document
          file={encodeURL(asset.url)}
          onLoadSuccess={handleDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (_el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={isMobile ? dimensions.width : 520}
              height={670}
            />
          ))}
        </Document>
      ) : (
        <Image
          onClick={handlePreviewClick}
          src={asset.thumbnailUrl ?? defaultBackgroundImg}
          height={dimensions.height}
          width={asset.width}
          alt={asset.originalName}
          className="h-full w-full cursor-pointer"
        />
      )}

      <div className="absolute bottom-4 right-4 z-10">
        <Button className="bg-gray-600" onClick={handlePreviewClick}>
          <FileText size={24} />
        </Button>
      </div>

      <PdfModal
        isShowed={isFullScreen}
        onSwipeComplete={setIsFullScreen}
        handleDocumentLoadSuccess={handleDocumentLoadSuccess}
        numPages={numPages}
        asset={asset}
      />
    </div>
  )
}
