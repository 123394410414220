import { ImageUploadProps } from '@api/uploads'
import { VideoUploadProps } from '@components/ui/video/VideoUpload'
import { useState } from 'react'

export interface MarketplaceAsset {
  url: string
  originalName: string
  mimeType: string
  isImage: boolean
  isVideo: boolean
  isFile: boolean
  height?: number
  width?: number
  isVertical?: boolean
  sizeBytes?: number
  thumbnailUrl?: string
  duration?: number
  pages?: number
  storyId?: string
  order?: number
}

export interface MarketplaceAssetUploadState {
  assetSelectionTriggered: boolean
  localAssetUrl: string
  isProcessingAsset: boolean
  isUploadingAsset: boolean
  assetProcessingProgress: number
  assetPreviewImage?: string
  processedAssetUri?: string
  signedUploadUrl?: string
  processedThumbnailAssetUri?: string
  signedUploadThumbnailUrl?: string
  finalAssetUrl?: string
  done?: boolean
  success?: boolean
  error?: string
  assetMetadata?: MarketplaceAsset
}

export const defaultMarketplaceAssetUploadState: MarketplaceAssetUploadState = {
  assetSelectionTriggered: false,
  isProcessingAsset: false,
  isUploadingAsset: false,
  assetProcessingProgress: 0,
  assetPreviewImage: undefined,
  processedAssetUri: undefined,
  signedUploadUrl: undefined,
  processedThumbnailAssetUri: undefined,
  signedUploadThumbnailUrl: undefined,
  finalAssetUrl: undefined,
  done: false,
  success: false,
  error: undefined,
  assetMetadata: undefined,
  localAssetUrl: '',
}

export const useUploadState = () =>
  useState<MarketplaceAssetUploadState>(defaultMarketplaceAssetUploadState)

export type MarketplaceUploadHookResponse = MarketplaceAssetUploadState & {
  reset: () => void
  shouldDisplayPreview: boolean
  uploadProcessedAsset?: (imageUploadBlob: ImageUploadProps) => Promise<void>
  uploadVideoProcessedAsset?: (
    videoUploadBlob: VideoUploadProps,
  ) => Promise<void>
  uploadImageOrVideoProcessedAsset?: (
    imageOrVideoUploadBlob: VideoUploadProps,
  ) => Promise<void>
}
