import { useLocalization } from '@goatlab/marketplace-i18n'
import { Badge, Button, useScrollHeight } from '@goatlab/react-ui'
import clsx from 'clsx'
import { HomeIcon, UsersRoundIcon } from 'lucide-react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, { useRef } from 'react'
import { AccountSwitcher } from '@components/account/AccountSwitcher'
import { useAuth } from '@components/auth/store/auth.store'
import { AiOutlineMessage } from 'react-icons/ai'
import { LanguageSwitcher } from '@components/ui/LanguageSwitcher'
import { Logo } from '@components/ui/Logo'
import { GealiumCategoryMenu } from '@components/ui/menus/GealiumCategoryMenu'
import { SpaceMenu } from '@components/ui/menus/SpaceMenu'
import { NoSSR } from '@components/ui/utils/NoSSR'
import { useSite } from '@contexts/site/site.context'
import { useUI } from '@contexts/ui/ui.context'
import { backendHook } from '@src/api/trpc'
import { useIs } from '@src/hooks/useIs'
import { useStateAwareRoutes } from '@src/hooks/useStateAwareRoutes'
import {
  formatMenuFromCategories,
  formatMenuFromWPMenus,
} from '@src/utils/buildHeaders'
import { useTypedTranslation } from '@src/utils/useTypedTranslation'
import { useAddActiveScroll } from '@utils/add-active-scroll'
import { sodiumConfig } from '../../../sodiumConfig'
import { SearchBox } from '@components/search/globalSearch/SearchBox'

const AuthMenu = dynamic(() => import('./authMenu'), { ssr: false })
const CartButton = dynamic(() => import('@components/cart/cartButton'), {
  ssr: false,
})

type DivElementRef = React.MutableRefObject<HTMLDivElement>

interface MainTopHeaderProps {
  logo?: string
}

export const MainTopHeader: React.FC<MainTopHeaderProps> = () => {
  const { t } = useTypedTranslation('common')
  const currentLanguage = useLocalization().currentLanguageBackendFormat()
  const { navbarOffset } = useScrollHeight()
  const { isSpace, menuItems, logo, isExternalDomain } = useSite()
  const router = useRouter()
  const routes = useStateAwareRoutes()
  const { user } = useAuth()

  const { data: gealiumCategories } = backendHook.categories.grouped.useQuery(
    {
      lang: currentLanguage,
    },
    {
      refetchOnWindowFocus: false,
    },
  )

  const { data } =
    backendHook.friendship.getFriendshipNotificationsCount.useQuery(undefined, {
      enabled: !!user?.account?.id,
    })
  const notificationsCount = data?.friendshipNotificationsCount || 0

  const { data: messageCount } =
    backendHook.chats.getUnreadConversationsCount.useQuery(undefined, {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
    })
  const inboxCount = messageCount || 0

  const shouldDisplaySpaceMenuOnly = !!(isSpace && isExternalDomain)
  const gealiumCategoryMenu = formatMenuFromCategories(gealiumCategories)
  const spaceMenu = formatMenuFromWPMenus(menuItems)

  const { openSearch } = useUI()
  const { isMobile, isMobilePage, isLargeDesktop } = useIs()
  const { isAuthenticated } = useAuth()
  const isUserAuthenticated = isAuthenticated()

  const siteHeaderRef = useRef() as DivElementRef

  useAddActiveScroll(siteHeaderRef)

  return (
    <header
      id="siteHeader"
      ref={siteHeaderRef}
      className={clsx('sticky active z-20 w-full h-14')}>
      <div
        className={clsx(
          'fixed z-20 w-full h-14 text-gray-700 transition duration-200 ease-in-out bg-white body-font ps-6',
        )}
        style={{
          top: `${navbarOffset}px`,
        }}>
        <div className="flex items-center  h-full w-full before:absolute before:w-screen before:h-px before:bg-[#F1F1F1] before:bottom-0 pr-4">
          <div className="w-2/12 inline-flex items-center justify-start">
            {isMobile && isUserAuthenticated && <AuthMenu />}
            {!isMobile && (
              <Logo
                keepText={!shouldDisplaySpaceMenuOnly}
                logoUrl={(shouldDisplaySpaceMenuOnly && logo) || ''}
              />
            )}
          </div>

          {sodiumConfig.isMarketPlace && (
            <div className="w-5/12 flex justify-around 2xl:me-12 3xl:me-20 mr-6">
              <div className="transition-all duration-100 ease-in-out lg:flex ms-7 xl:ms-9 pe-2 headerTopMenu">
                {shouldDisplaySpaceMenuOnly ? (
                  <SpaceMenu menu={spaceMenu} />
                ) : (
                  <GealiumCategoryMenu categoryMenu={gealiumCategoryMenu} />
                )}
              </div>
            </div>
          )}

          <div className="w-10/12 transition-all flex justify-between ">
            {/* Left-aligned SearchBox */}
            <div className="flex align-center m-2">
              <div className="flex flex-col justify-center">
                <SearchBox onFocus={openSearch} />
              </div>
            </div>

            {/* Right-aligned group of buttons and icons */}
            <div className="flex items-center justify-end ">
              {!isMobile && isUserAuthenticated && (
                <Button
                  onClick={async () => {
                    await router.push(routes.dynamic.ACCOUNT_HOME())
                  }}
                  variant="ghost"
                  color="transparent">
                  <div className="flex-col align-center">
                    <div className="flex items-center flex-col">
                      <HomeIcon className="w-5 h-5 mb-1" />
                      <span className="text-xs">Home</span>
                    </div>
                  </div>
                </Button>
              )}

              {!isMobile && isUserAuthenticated && (
                <Button
                  onClick={async () => {
                    await router.push(routes.static.NETWORK)
                  }}
                  variant="ghost"
                  color="transparent">
                  <div className="flex-col align-center relative">
                    <div className="flex items-center flex-col">
                      <UsersRoundIcon className="w-5 h-5 mb-1" />
                      <span className="text-xs">Network</span>
                    </div>
                    {notificationsCount > 0 && (
                      <Badge
                        variant="destructive"
                        className="absolute top-0 right-2 text-xs w-4 h-4 p-0 flex items-center justify-center">
                        {notificationsCount}
                      </Badge>
                    )}
                  </div>
                </Button>
              )}

              {isUserAuthenticated && (
                <Button
                  onClick={async () => {
                    await router.push(routes.dynamic.ACCOUNT_INBOX())
                  }}
                  variant="ghost"
                  color="transparent">
                  <div className="flex-col align-center relative">
                    <div className="flex items-center flex-col">
                      <AiOutlineMessage className="w-5 h-5 mb-1" />
                      <span className="text-xs">{t('text-inbox')}</span>
                    </div>
                    {inboxCount > 0 && (
                      <Badge
                        variant="destructive"
                        className="absolute top-0 right-0 text-xs w-4 h-4 p-0 flex items-center justify-center">
                        {inboxCount}
                      </Badge>
                    )}
                  </div>
                </Button>
              )}

              {sodiumConfig.isMarketPlace && (
                <div className="flex flex-col justify-center items-center">
                  <CartButton />
                </div>
              )}

              <NoSSR>
                <div className="hidden md:flex">
                  {!isMobile && !isUserAuthenticated && (
                    <LanguageSwitcher name="language" />
                  )}
                </div>
              </NoSSR>

              {isLargeDesktop && isUserAuthenticated && isMobilePage && (
                <AccountSwitcher />
              )}
              {!isMobile && (
                <div className="ml-4">
                  <AuthMenu />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
