import { backendHook } from '@api/trpc'
import { Arrays } from '@goatlab/js-utils'
import { useIs } from '@src/hooks/useIs'
import { defaultAccountImage } from '@src/utils/constants'
import React, { useCallback } from 'react'

export const NewsHubList = () => {
  const pullNewsAccounts = backendHook.account.getHighlightedAccounts.useQuery()

  const newsAccounts = pullNewsAccounts.data || []

  const handleAccountClick = useCallback((accountId: string) => {
    console.log('accountId', accountId)
  }, [])

  const { isLargeDesktop, isDesktop, isMobile } = useIs()
  const elementsPerChunk = isLargeDesktop || isDesktop ? 5 : isMobile ? 3 : 3

  const chunks = Arrays.chunk(newsAccounts, elementsPerChunk)
  return (
    <div className="flex flex-col gap-5">
      {chunks.map((chunk, index) => (
        <div className="flex justify-between gap-5" key={index}>
          {chunk.map(account => (
            <div
              key={account.id}
              className="flex flex-col items-center cursor-pointer pr-6"
              onClick={() => handleAccountClick(account.id)}>
              <img
                src={account.profilePicture || defaultAccountImage}
                alt={account.displayName || undefined}
                className="w-20 h-20 rounded-full object-cover"
              />
              <span className="pt-2 text-xs">{account.displayName}</span>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}
