export function getFileExtension(fileName: string) {
  const regex = /(?:\.([^.]+))?$/
  const match = regex.exec(fileName)
  if (match && match[1]) {
    return match[1]
  }

  return ''
}

export function removeFileExtension(fileName: string) {
  const lastDotIndex = fileName.lastIndexOf('.')
  if (lastDotIndex !== -1) {
    return fileName.slice(0, lastDotIndex)
  }
  return fileName
}
