import { z } from 'zod'

export const storageObjectResponseSchema = z.object({
  bucket: z.string(),
  contentType: z.string(),
  crc32c: z.string(),
  etag: z.string(),
  generation: z.string(),
  id: z.string(),
  kind: z.string(),
  md5Hash: z.string(),
  mediaLink: z.string(),
  metageneration: z.string(),
  name: z.string(),
  selfLink: z.string(),
  size: z.string(),
  storageClass: z.string(),
  timeCreated: z.string(),
  timeStorageClassUpdated: z.string(),
  updated: z.string(),
})
