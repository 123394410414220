import imageCompression, { Options } from 'browser-image-compression'

type ImageType = 'regular' | 'thumbnail'

class ImageService {
  async resizeAndCompressImage({
    imageFile,
    type = 'regular',
  }: {
    imageFile: File
    type?: ImageType
  }) {
    const maxWidthOrHeight = 1080
    let compression = 0.75

    if (type === 'thumbnail') {
      compression = 0.2
    }

    // Opciones para la biblioteca de compresión
    const options: Options = {
      maxSizeMB: compression,
      maxWidthOrHeight,
      useWebWorker: true,
      fileType: 'image/jpeg',
    }

    try {
      const compressedFile = await imageCompression(imageFile, options)
      const compressedBlob = new Blob([compressedFile], {
        type: options.fileType,
      })
      const compressedUrl = URL.createObjectURL(compressedFile)

      return {
        compressedBlob,
        compressedUrl,
      }
    } catch (err) {
      console.error('Error al comprimir y redimensionar la imagen:', err)
      throw new Error('Error al procesar la imagen')
    }
  }
}

export const imageService = new ImageService()
