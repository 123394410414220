import React, { FC } from 'react'
import { defaultAccountImage } from '@src/utils/constants'
import { sodiumConfig } from '@src/sodiumConfig'

interface AvatarProps {
  uri?: string | null
  size: number
}

export const Avatar: FC<AvatarProps> = ({ uri, size }) => {
  return (
    <img
      src={uri || defaultAccountImage}
      style={{
        width: size,
        height: size,
        borderRadius: size,
        backgroundColor: sodiumConfig.colors.iconColor,
        borderWidth: 0.5,
        borderColor: sodiumConfig.colors.iconColor,
      }}
      className="bg-white rounded-full aspect-square"
    />
  )
}
