import { FC, useRef, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { Button } from '@components/ui/buttons/Button2'
import { SpinnerLoader } from '@components/ui/loaders/SpinnerLoader'
import { VideoTrim } from './VideoTrim'
import { VideoFormats, VideoInputSettings } from '@src/utils/video/video.types'

export interface VideoUploadProps {
  videoBlob: Blob
  fileName: string
  mime: string
  videoSettings: VideoInputSettings
}

interface VideoUploadType {
  onUploadButtonPressed?: ({
    videoBlob,
    fileName,
    mime,
    videoSettings,
  }: VideoUploadProps) => void
  isLoading?: boolean
}

export const VideoUpload: FC<VideoUploadType> = ({
  onUploadButtonPressed,
  isLoading = false,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([])
  const [videoSettings, setVideoSettings] = useState<VideoInputSettings>({
    videoType: VideoFormats.MP4,
    customEndTime: 0,
    customStartTime: 0,
    removeAudio: false,
  })

  const [videoUrl, setVideoUrl] = useState<string | null>(null)

  const { getRootProps, getInputProps, isFileDialogActive } = useDropzone({
    accept: {
      'video/mp4': ['.mp4'],
      'video/webm': ['.webm'],
      'video/x-matroska': ['.mkv'],
      'video/quicktime': ['.mov'],
      'video/x-msvideo': ['.avi'],
      'video/x-flv': ['.flv'],
    },
    maxSize: 50000000,
    multiple: false,
    onDrop: files => {
      setAcceptedFiles(files)
    },
  })

  const handleUpload = () => {
    if (!acceptedFiles[0] || !videoRef.current) return

    onUploadButtonPressed?.({
      videoBlob: new Blob([acceptedFiles[0]], {
        type: acceptedFiles[0].type,
      }),
      fileName: acceptedFiles[0].name,
      mime: acceptedFiles[0].type,
      videoSettings,
    })
  }

  useEffect(() => {
    if (acceptedFiles[0]) {
      const newVideoUrl = URL.createObjectURL(acceptedFiles[0])
      setVideoUrl(newVideoUrl)

      return () => {
        URL.revokeObjectURL(newVideoUrl)
      }
    }
  }, [acceptedFiles])

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('loadedmetadata', () => {
        const duration = videoRef.current?.duration || 0
        setVideoSettings(settings => ({
          ...settings,
          customEndTime: duration,
        }))
      })
    }
  }, [videoUrl])

  return (
    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white w-full md:min-w-[600px]">
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <div className="text-center px-4">
          {acceptedFiles.length ? (
            isLoading ? (
              <>Uploading Video...</>
            ) : (
              <>
                <video
                  ref={videoRef}
                  controls
                  src={videoUrl || ''}
                  className="w-full h-64 object-contain mb-4"
                />
                <VideoTrim
                  videoSettings={videoSettings}
                  onVideoSettingsChange={setVideoSettings}
                  disable={isLoading || false}
                  videoRef={videoRef}
                />
              </>
            )
          ) : (
            <div
              {...getRootProps({ className: 'dropzone' })}
              className="bg-slate-100 p-6 cursor-pointer rounded-lg">
              <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-t from-slate-200 to-slate-100 mb-4">
                {isFileDialogActive ? (
                  <SpinnerLoader text="" className="mr-4" />
                ) : (
                  <svg className="w-5 h-6 fill-current" viewBox="0 0 20 24">
                    {/* SVG icon code */}
                  </svg>
                )}
              </div>
              <h2 className="text-2xl text-slate-800 font-bold mb-2">
                Upload your video
              </h2>
              <div className="mb-6">
                Click to select files or drag & drop some files here
              </div>
              <input {...getInputProps()} />
            </div>
          )}
        </div>
        {acceptedFiles.length ? (
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end mt-6 -mb-6">
            <Button
              variant="slim"
              onClick={handleUpload}
              loading={isLoading}
              color="black">
              {isLoading ? 'Loading...' : 'Upload'}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  )
}
