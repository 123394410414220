export enum VideoFormats {
  MP4 = 'mp4',
  MKV = 'mkv',
  MOV = 'mov',
  AVI = 'avi',
  FLV = 'flv',
  WEBM = 'webm',
}

export interface VideoInputSettings {
  videoType: VideoFormats
  customEndTime: number
  customStartTime: number
  removeAudio: boolean
}
