import { FC, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Button } from '@components/ui/buttons/Button2'
import { SpinnerLoader } from '@components/ui/loaders/SpinnerLoader'
import { ImageUploadProps } from '@api/uploads'

export interface DocumentUploadProps {
  documentBlob: Blob
  fileName: string
  mime: string
}

interface DocumentUploadType {
  onUploadButtonPressed?: ({
    imageBlob,
    fileName,
    mime,
  }: ImageUploadProps) => void
  isLoading?: boolean
}

export const DocumentUpload: FC<DocumentUploadType> = ({
  onUploadButtonPressed,
  isLoading,
}) => {
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([])
  const { getRootProps, getInputProps, isFileDialogActive } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
    },
    maxSize: 30000000,
    multiple: false,
    onDrop: handleFileUpload, // Ejecutar la carga directamente al soltar
  })

  async function handleFileUpload(files: File[]) {
    const file = files[0]
    if (!file) return
    setAcceptedFiles(files)

    const pdfBlob = new Blob([file], { type: file.type })

    // Llamar a la función de carga si está definida
    onUploadButtonPressed?.({
      imageBlob: pdfBlob,
      fileName: file.name,
      mime: file.type,
    })
  }

  return (
    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white w-full md:min-w-[600px]">
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <div>
          <div className="max-w-2xl m-auto mt-16">
            <div className="text-center px-4">
              {acceptedFiles?.length ? (
                isLoading ? (
                  <>Uploading Document...</>
                ) : (
                  <div className="text-lg text-slate-800 font-bold mb-4">
                    Ready to Upload PDF
                  </div>
                )
              ) : (
                <div
                  {...getRootProps({ className: 'dropzone' })}
                  className="bg-slate-100 p-6 cursor-pointer rounded-lg">
                  <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-t from-slate-200 to-slate-100 mb-4">
                    {isFileDialogActive ? (
                      <SpinnerLoader text="" className="mr-4" />
                    ) : (
                      <svg className="w-5 h-6 fill-current" viewBox="0 0 20 24">
                        <path
                          className="text-slate-500"
                          d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                        />
                        <path
                          className="text-slate-300"
                          d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                        />
                        <path
                          className="text-slate-400"
                          d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                        />
                      </svg>
                    )}
                  </div>
                  <h2 className="text-2xl text-slate-800 font-bold mb-2">
                    Upload your PDF
                  </h2>
                  <div className="mb-6">
                    Click to select a file or drag a PDF here
                  </div>
                  <input {...getInputProps()} />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Botón de subida (opcional) */}
        {acceptedFiles?.length ? (
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end mt-6 -mb-6">
            <Button
              variant="slim"
              onClick={() => handleFileUpload(acceptedFiles)}
              loading={isLoading}
              color="black">
              {isLoading ? 'Loading...' : 'Upload'}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  )
}
