import cn from 'clsx'
import { X } from 'lucide-react'
import React from 'react'
import SearchIcon from '@components/icons/search-icon'
import { useTypedTranslation } from '@src/utils/useTypedTranslation'
import { Strings } from '@goatlab/js-utils'
import { env } from '@src/_env/env'

interface SearchProps {
  className?: string
  onSubmit?: (e: React.SyntheticEvent) => void
  onClear?: (e: React.SyntheticEvent) => void
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void
  onFocus?: () => void
  showClear?: boolean
  name?: string
  value?: string
}

export const SearchBox = React.forwardRef<HTMLInputElement, SearchProps>(
  (
    { className, onSubmit, onClear, onFocus, showClear = false, ...rest },
    ref,
  ) => {
    const { t } = useTypedTranslation('search')
    const appName = Strings.upperFirst(env.APP_NAME)
    const placeHolder = `${t('search-in')} ${appName}`

    return (
      <form
        className={cn(
          'relative bg-[#F0F3F6] w-full overflow-hidden rounded-full',
          className,
        )}
        noValidate
        role="search"
        onSubmit={onSubmit}>
        <label htmlFor="searchBox" className="flex items-center">
          {/* Search Icon */}
          <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
            <SearchIcon className="w-5 h-5 text-gray-500" />
          </span>

          {/* Search Input */}
          <input
            id="searchBox"
            className="w-full h-9 pl-10 pr-12 bg-[#F0F3F6] text-sm placeholder-gray-400 text-black rounded-md outline-none"
            placeholder={placeHolder}
            aria-label="Search"
            autoComplete="off"
            onFocus={onFocus}
            ref={ref}
            {...rest}
          />
        </label>

        {/* Clear Button */}
        {showClear && (
          <button
            type="button"
            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
            onClick={onClear}>
            <X className="w-5 h-5" />
          </button>
        )}
      </form>
    )
  },
)
