import { useFlashListStore } from '@goatlab/marketplace-states'
import { useState, useCallback } from 'react'

interface UseAssetViewerProps {
  isViewable?: boolean
  postId?: string
}

export const useAssetViewer = ({ isViewable, postId }: UseAssetViewerProps) => {
  const { focusedItemId } = useFlashListStore()

  const isAssetViewable = isViewable && focusedItemId === postId

  const [currentAssetIndex, setCurrentAssetIndex] = useState<number>(0)

  const snapToItem = useCallback((itemIndex: number) => {
    setCurrentAssetIndex(itemIndex)
  }, [])

  return {
    currentAssetIndex,
    snapToItem,
    isAssetViewable,
  }
}
