import { PDFDocument } from 'pdf-lib'
import * as pdfjsLib from 'pdfjs-dist'
import imageCompression from 'browser-image-compression'
import { removeFileExtension } from '../files/utils'

export interface ThumbnailInput {
  documentFile: File
  fileName: string
  pageIndex?: number
  scale?: number
}

export interface ThumbnailResult {
  thumbnailBlob: Blob
  imageFile: File
  width: number
  height: number
}

class DocumentService {
  // Método para generar thumbnail en formato .jpeg
  async getDocumentThumbnail({
    documentFile,
    fileName,
    pageIndex = 0,
    scale = 1,
  }: ThumbnailInput): Promise<ThumbnailResult> {
    const pdfData = await documentFile.arrayBuffer()
    const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise
    const page = await pdf.getPage(pageIndex + 1)

    const viewport = page.getViewport({ scale })
    const canvas = this.createCanvas(viewport.width, viewport.height)
    const ctx = this.getCanvasContext(canvas)

    await page.render({ canvasContext: ctx, viewport }).promise

    // Convertimos el Data URL a Blob y luego a una URL de objeto para obtener una extensión .jpeg
    const thumbnailBlob = await this.dataURLToBlob(
      canvas.toDataURL('image/jpeg', 0.8),
    )
    const imageFile = new File([thumbnailBlob], removeFileExtension(fileName), {
      type: 'image/jpeg',
    })

    return {
      thumbnailBlob,
      imageFile,
      width: viewport.width,
      height: viewport.height,
    }
  }

  // Método para obtener el total de páginas del PDF
  async getTotalPagesFromPdf(pdfFile: File): Promise<number> {
    const pdfData = await pdfFile.arrayBuffer()
    const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise
    return pdf.numPages
  }

  async compressPdf(pdfFile: File, quality: number = 0.6): Promise<string> {
    const pdfData = await pdfFile.arrayBuffer()
    const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise

    const newPdfDoc = await PDFDocument.create()

    const pagePromises = Array.from({ length: pdf.numPages }, async (_, i) => {
      const page = await pdf.getPage(i + 1)
      const viewport = page.getViewport({ scale: 1 })

      const canvas = this.createCanvas(viewport.width, viewport.height)
      const ctx = this.getCanvasContext(canvas)
      await page.render({ canvasContext: ctx, viewport }).promise

      const imgDataUrl = canvas.toDataURL('image/jpeg', quality)
      const compressedImg = await this.compressImage(imgDataUrl, viewport.width)

      const imgBytes = await compressedImg.arrayBuffer()
      const imgPdf = await newPdfDoc.embedJpg(imgBytes)

      const pdfPage = newPdfDoc.addPage([viewport.width, viewport.height])
      pdfPage.drawImage(imgPdf, {
        x: 0,
        y: 0,
        width: viewport.width,
        height: viewport.height,
      })
    })

    // Ejecutamos todas las promesas en paralelo
    await Promise.all(pagePromises)

    const newPdfBytes = await newPdfDoc.save()
    const newPdfBlob = new Blob([newPdfBytes], { type: 'application/pdf' })
    return URL.createObjectURL(newPdfBlob)
  }

  // Helper: Crear canvas con tamaño dado
  private createCanvas(width: number, height: number): HTMLCanvasElement {
    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height
    return canvas
  }

  // Helper: Obtener el contexto 2D del canvas
  private getCanvasContext(
    canvas: HTMLCanvasElement,
  ): CanvasRenderingContext2D {
    const ctx = canvas.getContext('2d')
    if (!ctx) throw new Error('Failed to get 2D context')
    return ctx
  }

  // Helper: Convertir Data URL a Blob
  private async dataURLToBlob(dataURL: string): Promise<Blob> {
    const res = await fetch(dataURL)
    return await res.blob()
  }

  // Helper: Comprimir imagen con la librería de compresión
  private async compressImage(
    dataUrl: string,
    maxWidth: number,
  ): Promise<File> {
    const imgBlob = await (await fetch(dataUrl)).blob()
    const imgFile = new File([imgBlob], 'compressed-image.jpg', {
      type: 'image/jpeg',
    })
    return await imageCompression(imgFile, {
      maxSizeMB: 1,
      maxWidthOrHeight: maxWidth,
    })
  }
}

export const documentService = new DocumentService()
