import {
  Command,
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@goatlab/react-ui'
import { useEffect, JSX } from 'react'
import { useGlobalSearchFilter } from '../useGlobalSearchFilter'
import { OrganizationSearchResult } from './OrganizationSearchResult'
import { ProductSearchResult } from './ProductSearchResult'
import { StaffSearchResult } from './StaffSearchResult'
import { useSearch } from '@contexts/search/search.context'
import { useUI } from '@contexts/ui/ui.context'
import { typesenseService } from '@src/services/typesense/typesense.service'
import { AccountSearchResult } from '@components/account/home/AccountSearchResult'
import { Strings } from '@goatlab/js-utils'
import { env } from '@src/_env/env'
import { useTypedTranslation } from '@goatlab/marketplace-i18n'

export function Search(): JSX.Element {
  const { displaySearch, closeSearch, openSearch } = useUI()
  const searches = useGlobalSearchFilter()
  const multi = typesenseService.useMultiSearch(searches, {
    enabled: displaySearch,
    refetchOnWindowFocus: false,
  })
  const { setSearchQuery, q } = useSearch()
  const appName = Strings.upperFirst(env.APP_NAME)
  const { t } = useTypedTranslation(['search'])
  const placeHolder = `${t('search-in')} ${appName}...`

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        if (displaySearch) {
          closeSearch()
        } else {
          openSearch()
        }
      }
    }
    document.addEventListener('keydown', down)
    return () => document.removeEventListener('keydown', down)
  }, [])

  return (
    <CommandDialog
      open={displaySearch}
      onOpenChange={() => {
        if (displaySearch) {
          setSearchQuery('')
          closeSearch()
        } else {
          openSearch()
        }
      }}>
      <Command shouldFilter={false}>
        <CommandInput
          placeholder={placeHolder}
          value={q}
          onValueChange={setSearchQuery}
        />
        <CommandList>
          <CommandEmpty>No results found.</CommandEmpty>

          {multi?.data?.map(collectionResults => {
            const collectionName =
              collectionResults?.request_params?.collection_name

            return (
              <CommandGroup
                key={collectionName}
                heading={
                  collectionResults?.hits?.length ? collectionName : undefined
                }>
                {collectionResults?.hits?.map((item: any) => {
                  return (
                    <CommandItem
                      key={item.document.id}
                      value={item.document.id}>
                      {collectionName === 'accounts' && (
                        <AccountSearchResult account={item.document} />
                      )}

                      {collectionName === 'staff' && (
                        <StaffSearchResult item={item.document} />
                      )}

                      {collectionName === 'products' && (
                        <ProductSearchResult item={item.document} />
                      )}

                      {collectionName === 'organizations' && (
                        <OrganizationSearchResult item={item.document} />
                      )}
                    </CommandItem>
                  )
                })}
              </CommandGroup>
            )
          })}
        </CommandList>
      </Command>
    </CommandDialog>
  )
}
