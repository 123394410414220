import { z } from 'zod'
import { storageObjectResponseSchema } from './gcp.model'

class GcpService {
  /**
   * Asynchronously uploads a file to a given URL using a signed URL.
   *
   * @param blob Blob of the file to upload
   * @param signedUrl URL to which the file is uploaded
   * @param mime MIME type of the file
   * @returns Parsed response confirming upload details
   * @throws Error on failure to get file size, upload the file, or parse the response
   */
  async uploadFileToSignedUrl({
    blob,
    signedUrl,
    mime,
  }: {
    blob: Blob
    signedUrl: string
    mime: string
  }): Promise<z.infer<typeof storageObjectResponseSchema>> {
    try {
      const fileSize = blob.size

      console.log({ fileSize, mime, signedUrl, blob })

      if (!fileSize) throw new Error('Failed to retrieve file size.')

      const stream = new ReadableStream({
        start(controller) {
          const reader = blob.stream().getReader()

          function push() {
            reader
              .read()
              .then(({ done, value }) => {
                if (done) {
                  controller.close()
                  return
                }
                controller.enqueue(value)
                push()
              })
              .catch(err => {
                controller.error(err)
              })
          }

          push()
        },
      })

      const response = await fetch(signedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': mime,
          'Content-Length': `${fileSize}`,
        },
        body: stream,
        duplex: 'half', // Add this line
      } as RequestInit)

      if (!response.ok) {
        throw new Error(
          `Failed to upload file, server responded with status: ${response.status}`,
        )
      }

      const parsedResponse = await response.json()
      return storageObjectResponseSchema.parse(parsedResponse)
    } catch (err) {
      console.error('Error uploading file:', err)
      throw err
    }
  }

  async getFileSize(fileUri: string): Promise<number> {
    try {
      const response = await fetch(fileUri, { method: 'HEAD' })
      const contentLength = response.headers.get('Content-Length')

      if (contentLength) {
        const size = parseInt(contentLength, 10)
        console.log(`File size: ${size} bytes`)
        return size
      } else {
        console.log('File does not exist or size is unavailable')
        return 0
      }
    } catch (err) {
      console.error('Error getting file size:', err)
      return 0
    }
  }
}

export const gcpService = new GcpService()
