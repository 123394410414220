import Text from '@components/ui/Text'
import { sodiumConfig } from '@src/sodiumConfig'
import * as LucideIcons from 'lucide-react'
import React from 'react'

interface DataCenter {
  iconName: keyof typeof LucideIcons
  text: string
}

const dataCenterArray: DataCenter[] = [
  {
    iconName: 'BarChartIcon',
    text: 'Statistics',
  },
  {
    iconName: 'LineChartIcon',
    text: 'USDA $ Reports',
  },

  {
    iconName: 'PieChartIcon',
    text: 'Market Reports',
  },
  {
    iconName: 'ScatterChartIcon',
    text: 'Season Update',
  },
]

export const DataCenterList = () => {
  return (
    <div className="flex flex-row gap-4">
      {dataCenterArray.map((dataCenter, index) => {
        const IconComponent = LucideIcons[
          dataCenter.iconName
        ] as React.ElementType

        return (
          <div key={index} className="flex flex-col items-center gap-2">
            <div
              className="w-16 h-16 rounded-full  flex items-center justify-center md:w-16 md:h-16"
              style={{
                backgroundColor: sodiumConfig.colors.primary,
                opacity: 0.3,
              }}>
              <IconComponent
                className="w-8 h-8 md:w-8 md:h-8"
                color="#CDFD92"
              />
            </div>
            <Text variant="body" className="text-center">
              {dataCenter.text}
            </Text>
          </div>
        )
      })}
    </div>
  )
}
