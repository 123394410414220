import React, { FC, useEffect, useRef } from 'react'
import { Controller } from 'react-hook-form'
import { Label } from '../Label'
import { BaseField } from '../types/baseField'
import { useParseInputProps } from '@goatlab/react-zod-form'
import { QuillEditor } from './QuillEditor'
import type QuillClass from 'quill'
import { CameraIcon, FileText, Video } from 'lucide-react'

type RichTextAreaProps = BaseField<string> & {
  minHeight?: string
  autoFocus?: boolean
  onImagePressed?: () => void
  onVideoPressed?: () => void
  onDocumentPressed?: () => void
}

export const RichTextAreaComp: FC<RichTextAreaProps> = ({
  className = '',
  label,
  name: nameProp,
  formHook: hook,
  defaultValue,
  onChange,
  description,
  placeholder,
  disabled,
  autoFocus,
  onImagePressed,
  onVideoPressed,
  onDocumentPressed,
}) => {
  const quillRef = useRef<QuillClass | null>(null)

  const { name, formHook, errorMessage } = useParseInputProps({
    name: nameProp,
    formHook: hook,
  })

  const updateState = () => {
    const content = quillRef.current?.getContents().ops[0].insert
    const text = content ? `${content}` : ''

    formHook.setValue(name, text)
    onChange?.(text)
  }

  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        quillRef.current?.focus()
      }, 50)
    }
  }, [quillRef.current])

  useEffect(() => {
    if (defaultValue) {
      quillRef.current?.clipboard.dangerouslyPasteHTML(defaultValue)
    }
  }, [quillRef.current])

  return (
    <div className={className}>
      {label && <Label text={label} />}
      <Controller
        control={formHook?.control}
        name={name}
        defaultValue={defaultValue}
        disabled={disabled}
        render={({ field: { disabled } }) => {
          return (
            <>
              <div id="custom-toolbar">
                <button className="ql-bold"></button>
                <button className="ql-italic"></button>
                <button className="ql-underline"></button>
                <button className="ql-image">
                  <CameraIcon size={16} />
                </button>
                <button className="ql-video">
                  <Video size={16} />
                </button>
                <button className="ql-document">
                  <FileText size={16} />
                </button>
              </div>
              <QuillEditor
                quillRef={quillRef}
                readOnly={disabled}
                defaultValue={defaultValue}
                onTextChange={updateState}
                options={{
                  placeholder: placeholder || 'start typing...',
                  modules: {
                    toolbar: {
                      container: '#custom-toolbar',
                      handlers: {
                        document: () => {
                          onDocumentPressed?.()
                        },
                        image: () => {
                          onImagePressed?.()
                        },
                        video: () => {
                          onVideoPressed?.()
                        },
                      },
                    },
                  },
                  readOnly: disabled,
                }}
              />
            </>
          )
        }}
      />
      <h4 className="block text-xs font-medium mb-1 mt-1 ml-2">
        {description}
      </h4>
      {errorMessage && (
        <div className="mt-2 text-xs text-red-600 pl-1">
          {String(errorMessage)}
        </div>
      )}
    </div>
  )
}

export const RichTextArea = RichTextAreaComp
