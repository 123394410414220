import {
  FileBarChartIcon,
  HomeIcon,
  NewspaperIcon,
  UsersRoundIcon,
  CirclePlus,
} from 'lucide-react'
import { FC, useState } from 'react'
import { useSite } from '@contexts/site/site.context'
import { useRouter } from 'next/router'
import { useStateAwareRoutes } from '@src/hooks/useStateAwareRoutes'
import { NewsHubsDialog } from '@components/account/pages/NewsHubsDialog'
import { DataCenterDialog } from '@components/account/pages/DataCenterDialog'
import { Badge } from '@goatlab/react-ui'
import { backendHook } from '@api/trpc'
import { sodiumConfig } from '@src/sodiumConfig'
import { useAuth } from '@components/auth/store/auth.store'
import { CreatePostDialog } from '@components/account/home/CreatePostDialog'

export const MobileFooter: FC = () => {
  const [isShowNewHubs, setIsShowNewHubs] = useState(false)
  const [isShowDataCenter, setIsShowDataCenter] = useState(false)
  const [isShowPostModal, setIsShowPostModal] = useState(false)

  const { isExternalDomain } = useSite()
  const router = useRouter()
  const routes = useStateAwareRoutes()
  const { user } = useAuth()

  const { data } =
    backendHook.friendship.getFriendshipNotificationsCount.useQuery(undefined, {
      enabled: !!user?.account?.id,
    })
  const notificationsCount = data?.friendshipNotificationsCount || 0

  return (
    <div className="md:hidden fixed bottom-0 left-0 z-50 w-full h-12 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600">
      <div className="grid h-full max-w-lg grid-cols-5 mx-auto">
        <div className="flex items-center justify-center">
          <button
            type="button"
            className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
            {isExternalDomain ? (
              <HomeIcon
                className=" group-hover:text-primary h-5"
                color={sodiumConfig.colors.primary}
              />
            ) : (
              <HomeIcon
                className="h-5 w-5"
                color={sodiumConfig.colors.primary}
                onClick={() => router.push(routes.dynamic.ACCOUNT_HOME())}
              />
            )}
            <span className="text-xs text-slate-500 dark:text-gray-400 group-hover:text-primary dark:group-hover:text-primary">
              Home
            </span>
          </button>
        </div>

        <div className="relative flex items-center justify-center">
          <button
            type="button"
            onClick={async () => await router.push(routes.static.NETWORK)}
            className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
            <UsersRoundIcon
              className=" group-hover:text-primary h-5"
              color={sodiumConfig.colors.primary}
            />
            <span className="text-xs text-slate-500 dark:text-gray-400 group-hover:text-primary dark:group-hover:text-primary">
              Network
            </span>
          </button>
          {/* Badge para las notificaciones de Network */}
          {notificationsCount > 0 && (
            <Badge variant="destructive" className="absolute top-0 right-2">
              {notificationsCount}
            </Badge>
          )}
        </div>

        <div className="inline-flex flex-col items-center justify-center  hover:bg-gray-50 dark:hover:bg-gray-800 group">
          <CirclePlus
            onClick={() => setIsShowPostModal(true)}
            size={36}
            className="text-slate-500 group-hover:text-primary"
            color={sodiumConfig.colors.primary}
          />
        </div>

        <button
          type="button"
          onClick={() => setIsShowNewHubs(true)}
          className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
          <NewspaperIcon
            className="group-hover:text-primary h-5"
            color={sodiumConfig.colors.primary}
          />
          <span className="text-xs text-slate-500 dark:text-gray-400 group-hover:text-primary dark:group-hover:text-primary">
            NewsHub
          </span>
        </button>

        <button
          type="button"
          onClick={() => setIsShowDataCenter(true)}
          className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
          <FileBarChartIcon
            className="group-hover:text-primary h-5"
            color={sodiumConfig.colors.primary}
          />
          <span className="text-xs text-slate-500 dark:text-gray-400 group-hover:text-primary dark:group-hover:text-primary truncate">
            Data Center
          </span>
        </button>
      </div>
      <NewsHubsDialog
        isOpen={isShowNewHubs}
        onClose={() => setIsShowNewHubs(false)}
      />
      <DataCenterDialog
        isOpen={isShowDataCenter}
        onClose={() => setIsShowDataCenter(false)}
      />
      <CreatePostDialog
        isDialogOpen={isShowPostModal}
        setIsDialogOpen={setIsShowPostModal}
      />
    </div>
  )
}
