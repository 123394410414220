import { FC, useRef } from 'react'
import { Cropper } from 'react-cropper'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css'
import { useDropzone } from 'react-dropzone'
import { Button } from '@components/ui/buttons/Button2'
import { SpinnerLoader } from '@components/ui/loaders/SpinnerLoader'
import { ImageUploadProps } from '@api/uploads'

export interface PictureUploadProps {
  onUploadButtonPressed?: ({
    imageBlob,
    fileName,
    mime,
  }: ImageUploadProps) => void
  isLoading?: boolean
  cropResizable?: boolean
}

export const ImageUpload: FC<PictureUploadProps> = ({
  onUploadButtonPressed,
  isLoading,
  cropResizable,
}) => {
  const cropperRef = useRef<HTMLImageElement>(null)

  const { acceptedFiles, getRootProps, getInputProps, isFileDialogActive } =
    useDropzone({
      accept: {
        'image/png': ['.png'],
        'image/jpeg': ['.jpeg', '.jpg'],
      },
      maxSize: 30000000,
      multiple: false,
    })

  const onUploadPressed = async () => {
    const cropper = (cropperRef?.current as any).cropper as Cropper
    cropper.getCroppedCanvas().toBlob(imageBlob => {
      if (!imageBlob) {
        return
      }

      onUploadButtonPressed?.({
        imageBlob,
        fileName: acceptedFiles[0].name,
        mime: acceptedFiles[0].type,
      })
    })
  }

  return (
    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white w-full md:min-w-[600px]">
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        {/* Page header */}

        <div>
          <div className="max-w-2xl m-auto mt-16">
            <div className="text-center px-4">
              {acceptedFiles?.length ? (
                isLoading ? (
                  <>Uploading Picture...</>
                ) : (
                  <Cropper
                    src={URL.createObjectURL(acceptedFiles[0])}
                    style={{ height: 400, width: '100%' }}
                    // aspectRatio={1}
                    autoCrop={true}
                    modal={true}
                    center={true}
                    zoomable={true}
                    cropBoxResizable={cropResizable ?? false}
                    cropBoxMovable={true}
                    guides={true}
                    ref={cropperRef}
                  />
                )
              ) : (
                <div
                  {...getRootProps({ className: 'dropzone' })}
                  className="bg-slate-100 p-6 cursor-pointer rounded-lg">
                  <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-t from-slate-200 to-slate-100 mb-4">
                    {isFileDialogActive ? (
                      <SpinnerLoader text="" className="mr-4" />
                    ) : (
                      <svg className="w-5 h-6 fill-current" viewBox="0 0 20 24">
                        <path
                          className="text-slate-500"
                          d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                        />
                        <path
                          className="text-slate-300"
                          d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                        />
                        <path
                          className="text-slate-400"
                          d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                        />
                      </svg>
                    )}
                  </div>
                  <h2 className="text-2xl text-slate-800 font-bold mb-2">
                    Upload your image
                  </h2>
                  <div className="mb-6">
                    Click to select files or Drag drop some files here
                  </div>
                  <input {...getInputProps()} />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Right: Actions */}
        {acceptedFiles?.length ? (
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end mt-6 -mb-6">
            <Button
              variant="slim"
              onClick={onUploadPressed}
              loading={isLoading}
              color="black">
              {isLoading ? 'Loading...' : 'Upload'}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  )
}
