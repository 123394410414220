import { getTreeFromFlatData } from '@nosferatu500/react-sortable-tree'
import { RouterOutputs } from '@api/trpc'

export interface MenuItem {
  id: number
  path: string
  label: string
  description?: string
  menuOrder: number
  columns?: {
    id: number | string
    columnItems: MenuItem[]
  }[]
  subMenu?: MenuItem[]
}

type Categories = RouterOutputs['categories']['grouped']
export type MenuItems = RouterOutputs['menus']['getMenuItems']
type UnwrapedMenu = MenuItems[0]
type WpTreeMenu = UnwrapedMenu & { children: WpTreeMenu[] }

// The categories only have 3 levels deep
export const formatMenuFromCategories = (
  categories?: Categories,
): MenuItem[] => {
  if (!categories?.length) {
    return []
  }

  const tree =
    categories.find(c => c.name === 'Servicios' || c.name === 'Services')
      ?.children ?? []
  const menu: MenuItem[] = []

  const maxLevel2Links = 10
  const maxLevel3Links = 40

  // Level 1
  tree.forEach(item => {
    const numberOfItems = item.children?.length || 0
    const requiredColumns = Math.ceil(numberOfItems / maxLevel2Links)

    const columns = Array.from(
      Array.from({ length: requiredColumns }).keys(),
    ).map(key => {
      return {
        id: key,
        columnItems: [],
      }
    })

    const menuItem: MenuItem = {
      id: item.id,
      path: `/search?category=${item.name.toLowerCase()}`,
      menuOrder: item.menu_order,
      label: item.name,
      columns,
    }
    menu.push(menuItem)

    // Level 2
    item.children?.forEach((child, index) => {
      const category: any = {
        id: child.id,
        path: `/search?category=${item.slug}%2C${child.slug}`,
        label: child.name,
        columnItemItems: [],
      }
      // Level 3
      child.children?.forEach((subChild, index) => {
        if (index < maxLevel3Links) {
          category.columnItemItems.push({
            id: subChild.id,
            path: `/search?category=${item.slug}%2C${child.slug}%2C${subChild.slug}`,
            label: subChild.name,
          })
        }
      })

      const columnNumber = Math.ceil(index / maxLevel2Links) - 1
      const designatedColumn = Math.max(columnNumber, 0)

      menuItem?.columns?.[designatedColumn]?.columnItems.push(category)
    })
  })

  return menu
}

export const formatMenuFromWPMenus = (menus?: MenuItems): MenuItem[] => {
  if (!menus?.length) {
    return []
  }

  const tree = getTreeFromFlatData({
    flatData: menus,
    getKey: i => {
      return i.id
    },
    getParentKey(node) {
      return node.parent
    },
    rootKey: '0',
  }) as WpTreeMenu[]

  const menu: MenuItem[] = []

  const maxLevel2Links = 10
  const maxLevel3Links = 40

  // Level 1
  tree.forEach(item => {
    const numberOfItems = item.children?.length || 0
    const requiredColumns = Math.ceil(numberOfItems / maxLevel2Links)

    const columns = Array.from(
      Array.from({ length: requiredColumns }).keys(),
    ).map(key => {
      return {
        id: key,
        columnItems: [],
      }
    })

    const menuItem: MenuItem = {
      id: item.id,
      path: item.url,
      description: item.description,
      menuOrder: item.menu_order,
      label:
        typeof item.title === 'string'
          ? item.title
          : item.title?.rendered ?? '',
      columns,
    }
    menu.push(menuItem)

    // Level 2
    item.children?.forEach((child, index) => {
      const category: any = {
        id: child.id,
        path: child.url,
        description: child.description,
        menuOrder: child.menu_order,
        label:
          typeof child.title === 'string'
            ? child.title
            : child.title?.rendered ?? '',
        columnItemItems: [],
      }
      // Level 3
      child.children?.forEach((subChild, index) => {
        if (index < maxLevel3Links) {
          category.columnItemItems.push({
            id: subChild.id,
            path: subChild.url,
            menuOrder: subChild.menu_order,
            description: subChild.description,
            label:
              typeof subChild.title === 'string'
                ? subChild.title
                : subChild.title?.rendered ?? '',
          })
        }
      })

      const columnNumber = Math.ceil(index / maxLevel2Links) - 1
      const designatedColumn = Math.max(columnNumber, 0)

      menuItem?.columns?.[designatedColumn]?.columnItems.push(category)
    })
  })

  return menu
}
